<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm9 md10 text-left>
        <span class="mainHeader">Sales History</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right>
        <v-text-field
          background-color="white"
          v-model="keyword"
          color="#b234a9"
          clearable
          hide-details
          placeholder="Search Bill"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
     
      <v-flex xs12 py-6 text-left>
        <v-card
          class="rounded-lg"
          elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="dataList">
            <v-flex xs12 v-if="dataList.length>0">
              <template >
  <v-data-table
    :headers="Headers"
    :items="dataList"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="_id._id"
    show-expand
    class="elevation-0"
    :header-props="{
    class: 'custom-header'
  }"
  >
  <template #item._id.create_date="{ item }">
    {{ formatDate(item._id.create_date) }}
  </template>
    <!-- <template v-slot:top>
      <v-toolbar flat class="px-0 mx-0">
        <v-toolbar-title class="tablefont">Expandable Table</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-switch
          v-model="singleExpand"
          label="Single expand"
          class="mt-2"
        ></v-switch>
      </v-toolbar>
    </template> -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="px-0">
   <!-- <v-card class="pa-4" elevation="0"> -->
    <v-layout wrap pa-2>
      <v-flex xs12 sm10 py-1 pl-4 >
        <span class="tablefont">Item Details:</span>
      </v-flex>
      <v-flex xs12 sm2 text-right py-1 pl-4 >
      <router-link :to="getLink(item)">
        <v-btn block small text color="#3F053C" class="table"><v-icon class="pr-2">mdi-file-document-arrow-right-outline</v-icon>View Bill</v-btn>
        </router-link>
      </v-flex>
      <v-flex xs12>
        <v-simple-table>
        <thead>
                          <tr>
                            <th class="text-left tablefont0"><b>S.No.</b></th>
                            <th class="text-left tablefont0"><b>Code</b></th>
                            <th class="text-left tablefont0"><b>Count</b></th>
                            <!-- <th class="text-left tablefont0"><b>Gross.Wt </b></th> -->
                            <th class="text-left tablefont0"><b>Item/Wt(gm)</b></th>
                            <th class="text-left tablefont0"><b>Net.Wt(gm)</b></th>
                            <th class="text-left tablefont0"><b>StonePrice(₹)</b></th>
                            <th class="text-left tablefont0"><b>Stone.Wt(gm)</b></th>
                            <th class="text-left tablefont0"><b>Total(₹)</b></th>
                            <th class="text-left tablefont0"><b>Type</b></th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, i) in item.items"
                            :key="i"
                            class="table"
                          >
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.designId">{{
                                value.designId.code
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.quantity"> {{ value.quantity }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.itemPerWeight">{{
                                value.itemPerWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.netWeight">{{ value.netWeight }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stonePrice">{{
                                value.stonePrice
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.stoneWeight">{{
                                value.stoneWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            
                            <td  v-if="value.taxType==true">
                              <span v-if="value.amountWithGst">{{
                                (value.amountWithGst).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td  v-else>
                              <span v-if="value.amountWithoutGst">{{
                                (value.amountWithoutGst).toFixed(2)
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.taxType==true">GST</span>
                              <span v-else>Demo</span>
                            </td>
                          </tr>
                        </tbody>
                    </v-simple-table>
      </v-flex>
    </v-layout>
  
   <!-- </v-card> -->
   </td>
    </template>
  </v-data-table>
</template>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
     
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page: 1,
      // Pagelength: null,
      dataList:[],
      keyword:null,
    juwellaryId: localStorage.getItem("juwellaryId"),
        // expanded: [],
        // singleExpand: false,
        Headers: [
          {
            text: 'Bill.No',
            align: 'start',
            sortable: false,
            value: '_id.billNo',
          },
          { text: 'Date', value: '_id.create_date' },
          { text: 'Net.Wt(gm)', value: '_id.totalNetWeight' },
          { text: 'Returns(₹)', value: '_id.returnAmount' },
          { text: 'Old Gold(₹)', value: '_id.oldGoldAmount' },
          { text: 'Total(₹)', value: '_id.totalAmount' },
          { text: '', value: 'data-table-expand' },
        ],
        expanded: [],
        singleExpand: true,
      
    
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    keyword() {
      this.getData();
    },
  },
  computed: {
    getLink() {
      return (item) => `/billDetail?id=${item._id._id}`;
    },
  },

  methods: {
   
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/sales/alllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          keyword: this.keyword,
          page: this.page,
          count: 20,
        jewelleryId: this.juwellaryId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dataList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);

          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
    var dt = new Date(item);
    var day = dt.getDate();
    var year = dt.getFullYear();
    // var hours = dt.getHours();
    // var minutes = dt.getMinutes();
    dt = dt.toString();
    // var ampm = hours >= 12 ? "pm" : "am";
    // hours = hours % 12;
    // hours = hours ? hours : 12;
    // minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = day + " " + dt.slice(4, 7) + " " + year;
    //  +
    // " , " +
    // hours +
    // ":" +
    // minutes +
    // " " +
    // ampm;

    return strTime;
  },
  },
};
</script>
<style scoped>
.v-text-field input {
  font-family: kumbhBold;
  }
  .custom-header {
    font-size: 15px !important;
  color: rgb(0, 0, 0) !important;
  text-transform: uppercase;
  font-family: DmSansBold !important;
  }
</style>
